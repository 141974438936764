.logos-grid {
  display: grid;
  grid-auto-flow: dense;
  align-items: stretch;
  column-gap: $block-gap + 2px;
  grid-template-columns: repeat(12, 1fr);
  width: calc(100% + #{$block-gap});
  margin-left: -$block-gap/2;
  margin-right: -$block-gap/2;

  &__block {
    position: relative;
    padding: $block-gap $block-gap/2;
    padding-bottom: calc(#{$block-gap}*2);
    grid-column: span 6;

    @media #{$breakpoint-sm-up} {
      grid-column: span 4;
    }

    @media #{$breakpoint-md-up} {
      grid-column: span 3;
    }

    img + p {
      margin-top: 1em;
    }
  }
}
