ul, ol {
  margin: 0;
  padding-left: 0;
}

ul {
  padding-left: 0.9em;
}

textarea,
input,
select {
  border: 0;
  outline: 0;
  border-radius: 0;
  border: 1px solid $color-grey;
  padding: 0.5em 0.3em;
  font-weight: 300;
  margin-bottom: 0.5em;
  width: 100%;
  min-width: 150px;

  [data-bg-color="red"] & {
    border-color: $color-red;
  }

  [data-bg-color="blue"] & {
    border-color: $color-blue;
  }

  [data-bg-color="yellow"] & {
    border-color: $color-yellow;
  }

  [data-bg-color="orange"] & {
    border-color: $color-orange;
  }

  [data-bg-color="pink"] & {
    border-color: $color-pink;
  }

  [data-bg-color="green"] & {
    border-color: $color-green;
  }

  [data-bg-color="grey"] & {
    border-color: $color-grey;
  }
}

textarea {
  min-height: 10em;
}

select {

  @media #{$breakpoint-sm-up} {
    width: 150px;
  }
}

input[type="submit"] {
  border: 0;
  width: auto;
  margin-right: 0;
  margin-left: auto;
  display: block;
  line-height: 0.8;
  padding: 0.8em 0.3em;
  padding-top: 1em;

  [data-bg-color="red"] & {
    background-color: $color-red;
  }

  [data-bg-color="blue"] & {
    background-color: $color-blue;
  }

  [data-bg-color="yellow"] & {
    background-color: $color-yellow;
  }

  [data-bg-color="orange"] & {
    background-color: $color-orange;
  }

  [data-bg-color="pink"] & {
    background-color: $color-pink;
  }

  [data-bg-color="green"] & {
    background-color: $color-green;
  }

  [data-bg-color="grey"] & {
    background-color: $color-grey;
  }

  &:hover {
    cursor: pointer;
    background-color: black;
    color: white;
  }
}
