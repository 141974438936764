html {
  font-size: $font-size-base;

  @media #{$breakpoint-sm-up} {
    font-size: $font-size-base--mobile;
  }
}

* {
  font-family: 'Maison', sans-serif;
  font-size: $p-font-size;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
}

p, h1, h2, h3, h4, h5, h6, ul, ol, li {
  margin: 0;
}

a {
  color: initial;
  text-decoration-line: none;
  text-underline-offset: 0.2em;

  &:hover {
    text-decoration-line: underline;
  }
}

h1, h1 * {
  font-weight: bold;
  line-height: 1.2;
  font-size: $h1-font-size--mobile;

  & a {
    text-decoration-thickness: $border-colored-width--mobile;

    @media #{$breakpoint-sm-up} {
      text-decoration-thickness: $border-colored-width;
    }
  }

  @media #{$breakpoint-sm-up} {
    font-size: $h1-font-size;
  }
}

h2, h2 * {
  font-weight: bold;
  line-height: 1.2;
  font-size: $h2-font-size--mobile;

  @media #{$breakpoint-sm-up} {
    font-size: $h2-font-size;
  }

  & a {
    text-decoration-thickness: $border-colored-width--mobile;

    @media #{$breakpoint-sm-up} {
      text-decoration-thickness: $border-colored-width;
    }
  }
}

h3, h3 * {
  font-weight: bold;
  line-height: 1.2;
  font-size: $h3-font-size--mobile;

  @media #{$breakpoint-sm-up} {
    font-size: $h3-font-size;
  }

  [data-bg-color="red"] & {
    color: $color-red;
  }

  [data-bg-color="blue"] & {
    color: $color-blue;
  }

  [data-bg-color="yellow"] & {
    color: $color-yellow;
  }

  [data-bg-color="orange"] & {
    color: $color-orange;
  }

  [data-bg-color="pink"] & {
    color: $color-pink;
  }

  [data-bg-color="green"] & {
    color: $color-green;
  }

  [data-bg-color="grey"] & {
    color: $color-grey;
  }
}

h4, h4 * {
  font-weight: bold;
  line-height: 1.2;
  font-size: $h4-font-size--mobile;

  @media #{$breakpoint-sm-up} {
    font-size: $h4-font-size;
  }

  [data-bg-color="red"] & {
    color: $color-red;
  }

  [data-bg-color="blue"] & {
    color: $color-blue;
  }

  [data-bg-color="yellow"] & {
    color: $color-yellow;
  }

  [data-bg-color="orange"] & {
    color: $color-orange;
  }

  [data-bg-color="pink"] & {
    color: $color-pink;
  }

  [data-bg-color="green"] & {
    color: $color-green;
  }

  [data-bg-color="grey"] & {
    color: $color-grey;
  }
}

h5, h5 * {
  font-weight: 500;
  line-height: 1.2;
  font-size: $h5-font-size--mobile;

  @media #{$breakpoint-sm-up} {
    font-size: $h5-font-size;
  }
}

h6, h6 * {
  font-weight: 300;
  line-height: 1.2;
  font-size: $h6-font-size--mobile;

  @media #{$breakpoint-sm-up} {
    font-size: $h6-font-size;
  }
}

p.p--small, p.p--small *,
ul.p--small, ul.p--small *,
ol.p--small, ol.p--small * {
  font-size: $p-small-font-size--mobile;

  @media #{$breakpoint-sm-up} {
    font-size: $p-small-font-size;
  }
}

.p--smaller, .p--smaller * {
  font-size: $p-smaller-font-size--mobile;

  @media #{$breakpoint-sm-up} {
    font-size: $p-smaller-font-size;
  }
}

.text--tag {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.7em;
  vertical-align: middle;
  margin-left: 0.5em;

  [data-bg-color="red"] & {
    color: $color-red;
  }

  [data-bg-color="blue"] & {
    color: $color-blue;
  }

  [data-bg-color="yellow"] & {
    color: $color-yellow;
  }

  [data-bg-color="orange"] & {
    color: $color-orange;
  }

  [data-bg-color="pink"] & {
    color: $color-pink;
  }

  [data-bg-color="green"] & {
    color: $color-green;
  }

  [data-bg-color="grey"] & {
    color: $color-grey;
  }
}
