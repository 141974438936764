.people {
  display: flex;
  flex-wrap: wrap;
  @media #{$breakpoint-sm-up} {
    flex-wrap: no-wrap;
  }

  &__filters {
    flex-basis: calc(100% / 12 * 12);
    display: flex;
    flex-direction: column;

    @media #{$breakpoint-md-up} {
      padding-right: $block-gap;
      flex-basis: calc(100% / 12 * 3);
    }
  }

  &__container {
    margin-top: $block-gap*2;
    flex-basis: calc(100% / 12 * 12);
    overflow: hidden;

    @media #{$breakpoint-md-up} {
      flex-basis: calc(100% / 12 * 9);
      margin-top: 0;
      padding-left: $block-gap;
    }
  }

  &__content {
    width: calc(100% + #{$block-gap});
    margin-left: -$block-gap/2;
    margin-right: -$block-gap/2;
    display: grid;
    grid-auto-flow: dense;
    align-items: stretch;
    column-gap: $block-gap + 2px;
    grid-template-columns: repeat(12, 1fr);
    margin-bottom: $border-vert-colored-width * -1;
  }


  &__member {
    position: relative;
    padding: $block-gap $block-gap/2;
    padding-bottom: calc(#{$block-gap}*2);
    grid-column: span 12;
    text-align: center;

    @media #{$breakpoint-sm-up} {
      grid-column: span 4;
      text-align: left;
    }

    .people--small & {
      grid-column: span 6;

      @media #{$breakpoint-sm-up} {
        grid-column: span 4;
      }

      @media #{$breakpoint-md-up} {
        grid-column: span 3;
      }
    }

    img {
      width: 50%;
      margin-bottom: $block-gap/2;

      @media #{$breakpoint-sm-up} {
        width: 70%;
      }

      .people--small & {
        width: 70%;
      }
    }

    border-bottom: $border-vert-colored-width solid $color-grey;

    [data-bg-color="red"] & {
      border-bottom-color: $color-red;
    }

    [data-bg-color="blue"] & {
      border-bottom-color: $color-blue;
    }

    [data-bg-color="yellow"] & {
      border-bottom-color: $color-yellow;
    }

    [data-bg-color="orange"] & {
      border-bottom-color: $color-orange;
    }

    [data-bg-color="pink"] & {
      border-bottom-color: $color-pink;
    }

    [data-bg-color="green"] & {
      border-bottom-color: $color-green;
    }

    [data-bg-color="grey"] & {
      border-bottom-color: $color-grey;
    }

    & + .people__member:before {
      content: '';
      position: absolute;
      left: calc((#{$block-gap}/2 + #{$border-vert-colored-width}) * -1);
      top: calc(#{$block-gap} - (#{$border-colored-width--mobile} - #{$border-vert-colored-width}));
      bottom: calc(#{$block-gap} - (#{$border-colored-width--mobile} - #{$border-vert-colored-width}));
      border-left: $border-vert-colored-width solid $color-grey-darker;

      @media #{$breakpoint-sm-up} {
        top: calc(#{$block-gap} - (#{$border-colored-width} - #{$border-vert-colored-width}));
        bottom: calc(#{$block-gap} - (#{$border-colored-width} - #{$border-vert-colored-width}));
      }

      [data-bg-color="red"] & {
        border-left-color: $color-red;
      }

      [data-bg-color="blue"] & {
        border-left-color: $color-blue;
      }

      [data-bg-color="yellow"] & {
        border-left-color: $color-yellow;
      }

      [data-bg-color="orange"] & {
        border-left-color: $color-orange;
      }

      [data-bg-color="pink"] & {
        border-left-color: $color-pink;
      }

      [data-bg-color="green"] & {
        border-left-color: $color-green;
      }

      [data-bg-color="grey"] & {
        border-left-color: $color-grey;
      }
    }
  }
}
