.pub-list {
  display: flex;
  flex-wrap: wrap;
  @media #{$breakpoint-sm-up} {
    flex-wrap: no-wrap;
  }

  &__filters {
    flex-basis: calc(100% / 12 * 12);
    display: flex;
    flex-direction: column;

    @media #{$breakpoint-sm-up} {
      padding-right: $block-gap;
      flex-basis: calc(100% / 12 * 3);
    }
  }

  &__content {
    margin-top: $block-gap*2;
    flex-basis: calc(100% / 12 * 12);

    > * + * {
      margin-top: $block-gap;
    }

    @media #{$breakpoint-sm-up} {
      margin-top: 0;
      padding-left: $block-gap;
      flex-basis: calc(100% / 12 * 9);
    }

    @media #{$breakpoint-md-up} {
      flex-basis: calc(100% / 12 * 6);
    }
  }

  &__pagination {
    border: 1px solid transparent;
    padding: 0.5em 0.2em;
    font-weight: 300;
    text-align: center;

    @media #{$breakpoint-sm-up} {
      width: 150px;
      text-align: left;
    }
  }

  .sub-section:first-child {
    @media #{$breakpoint-sm-up} {
      border-top: 0;
      padding-top: 0;
    }
  }

  &__publication {
    margin-top: $block-gap*2;

    @media #{$breakpoint-sm-up} {
      margin-top: $block-gap;
    }

    &:not(:first-child) {
      padding-top: $block-gap;
      border-top: 1px solid $color-grey;

      @media #{$breakpoint-sm-up} {
        padding-top: $block-gap/2;
      }

      [data-bg-color="red"] & {
        border-color: $color-red;
      }

      [data-bg-color="blue"] & {
        border-color: $color-blue;
      }

      [data-bg-color="yellow"] & {
        border-color: $color-yellow;
      }

      [data-bg-color="orange"] & {
        border-color: $color-orange;
      }

      [data-bg-color="pink"] & {
        border-color: $color-pink;
      }

      [data-bg-color="green"] & {
        border-color: $color-green;
      }

      [data-bg-color="grey"] & {
        border-color: $color-grey;
      }
    }
  }
}
