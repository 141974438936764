.sub-section {
  border-top: $border-vert-colored-width solid $color-grey;
  padding-bottom: calc(#{$block-gap}*2);
  padding-top: $block-gap/2;

  @media #{$breakpoint-sm-up} {
    padding-left: $block-gap $block-gap/2;
    padding-right: $block-gap $block-gap/2;
  }

  & + & {
    margin-top: $block-gap;
  }

  [data-bg-color="red"] & {
    border-top-color: $color-red;
  }

  [data-bg-color="blue"] & {
    border-top-color: $color-blue;
  }

  [data-bg-color="yellow"] & {
    border-top-color: $color-yellow;
  }

  [data-bg-color="orange"] & {
    border-top-color: $color-orange;
  }

  [data-bg-color="pink"] & {
    border-top-color: $color-pink;
  }

  [data-bg-color="green"] & {
    border-top-color: $color-green;
  }

  [data-bg-color="grey"] & {
    border-top-color: $color-grey;
  }

  > * + * {
    margin-top: $block-gap;
  }

  p + p {
    margin-top: 0;
  }

  &__title {
    margin-bottom: 0.5em;
  }
}
