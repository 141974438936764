.footer {
  border-top: $border-colored-width--mobile solid black;

  @media #{$breakpoint-sm-up} {
    border-top-width: $border-colored-width;
  }

  &, & * {
    @extend .p--smaller;
    line-height: 1.3;
  }

  &__group {
    padding: $block-gap $block-gap/2;
    padding-top: calc(#{$block-gap}*0.7);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media #{$breakpoint-md-up} {
      flex-wrap: no-wrap;
    }

    & + & {
      border-top: $border-vert-colored-width solid black;
    }

    & > * {
      flex-basis: 100%;
      flex-grow: 0;
      margin-bottom: 1.3em;

      @media #{$breakpoint-sm-up} {
        flex-basis: 70%;
      }

      @media #{$breakpoint-md-up} {
        flex-basis: 30%;
        margin-bottom: 0;
      }
    }

    &--links .footer__links-group {
      flex-basis: 100%;
    }
  }

  &__logo {
    flex-basis: 100%;

    @media #{$breakpoint-md-up} {
      flex-basis: 20%;
    }

    img {
      min-width: 80px;
      max-width: 180px;
    }
  }

  &__links {
    flex-basis: 100%;

    @media #{$breakpoint-md-up} {
      flex-basis: 40%;
    }
  }

  &__links-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    & + & {
      padding-top: 0.5em;
      @media #{$breakpoint-md-up} {
        padding-top: 1.3em;
      }
    }

    a {
      text-transform: uppercase;
      padding-right: 1em;
    }
  }
}
