/* -- Grid -- */
$grid-columns: 10; // Set number of columns in the grid

$xs-max: 640px; // 40rem
$sm-max: 1023px; // 64rem
$md-max: 1439px; // 90rem
$lg-max: 1790px; // $lg-max: 1920px; // 120rem

/* GUTTER AND CONTAINER PAD */
/* gutter and container pad changes on sm */
$gutter-size: 7.5px;
$gutter: 0 $gutter-size; // Set gutter size
$container-pad: $gutter-size *4;


$margin-container: 10%;
$margin-container-vw: 10vw;
$margin-container-percent: 0.1;

$margin-container-mobile: 5%;
$margin-container-mobile-vw: 5vw;
$margin-container-mobile-percent: 0.05;

$border-colored-width--mobile: 3px;
$border-colored-width: 5px;
$border-vert-colored-width: 2px;

$block-gap: $gutter-size *2;

/* -- Font sizes -- */
/* - desktop - changes on lg */
$font-size-base: 16.5px;

$p-font-size: 1rem; //16px
$p-small-font-size: 0.85rem; //14px
$p-smaller-font-size: 0.73rem; //12px
$h1-font-size: 2.24rem; //37px
$h2-font-size: 1.94rem; //32px
$h2-font-size: 1.39rem; //23px
$h3-font-size: 1.39rem; //23px
$h4-font-size: 1.27rem; //21px
$h5-font-size: 1.27rem; //21px
$h6-font-size: 1.27rem; //21px

$menu-font-size: 0.97rem; //16px

/* - tablet - changes on md */
$menu-font-size--tablet: 1.35rem; //24px

/* - mobile - changes on sm */
$font-size-base--mobile: 16px;

$p-small-font-size--mobile: 0.93rem; //15px
$p-smaller-font-size--mobile: 0.87rem; //14px
$h1-font-size--mobile: 1.35rem; //24px
$h2-font-size--mobile: 1.25rem; //20px
$h3-font-size--mobile: 1.25rem; //20px
$h4-font-size--mobile: 1.12rem; //18px
$h5-font-size--mobile: 1.12rem; //18px
$h6-font-size--mobile: 1.12rem; //18px

$menu-font-size--mobile: 0.97rem; //

//the design layout from mobile to desktop changes on md

/* -- Colors -- */
$color-blue: #237dd2;
$color-pink: #e1b0bd;
$color-yellow: #f0e678;
$color-grey: #c8c8c8;
$color-grey-darker: #646464;
$color-red: #ff4b64;
$color-green: #54c8a0;
$color-orange: #e26956;


/* Z-index */
$z-menu-hamb: 11;
$z-header-mobile: 10;
