$gradient-marg-mobile: 5%;
$gradient-marg-mobile--right: 95%;

$gradient-marg: 8%;
$gradient-marg--right: 92%;

body {
  background: $color-blue;
  background: linear-gradient(90deg, $color-yellow 0%, $color-blue #{$gradient-marg-mobile}, $color-blue #{$gradient-marg-mobile--right}, $color-pink 100%);

  @media #{$breakpoint-lg-up} {
    background: linear-gradient(90deg, $color-yellow 0%, $color-blue #{$gradient-marg}, $color-blue #{$gradient-marg--right}, $color-pink 100%);
  }
}

.body-container {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.body-content {
  background-color: white;
  max-width: 1920px;
  margin-left: $margin-container-mobile;
  margin-right: $margin-container-mobile;
  padding-top: 0;

  @media #{$breakpoint-sm-up} {
    padding: $gutter-size;
    padding-top: 0;
  }

  @media #{$breakpoint-md-up} {
    display: flex;
    padding: $gutter-size*2;
    padding-top: $gutter-size*4;
  }

  @media #{$breakpoint-lg-up} {
    margin-left: $margin-container;
    margin-right: $margin-container;
  }

  &__header {
    flex-basis: 20%;
  }

  &__body-page {
    position: relative;
    min-height: calc(100vh - #{$gutter-size}*4*2);
    flex-basis: 100%;
    background-color: white;
    @media #{$breakpoint-md-up} {
      flex-basis: 80%;
    }
  }
}


.link__more {
  margin-left: 0.5em;
  font-size: max(0.6em, 10px) !important;
  text-transform: uppercase;
  text-decoration-line: underline;
  text-decoration-thickness: 0.15em;
  text-underline-offset: 0.2em;
  text-decoration-color: $color-blue;
}

body[data-bg-color="red"] {
  background: $color-red;
  background: linear-gradient(90deg, $color-yellow 0%, $color-red #{$gradient-marg}, $color-red #{$gradient-marg--right}, $color-pink 100%);

  .page-grid__block {
    &:not(&--no-border) {
      border-top-color: $color-red;
    }

    & + .page-grid__block:before {
      border-left-color: $color-red;
    }
  }

  .mix-blend-bg {
    background-color: $color-red;
  }

  a {
    text-decoration-color: $color-red;
  }
}

body[data-bg-color="blue"] {
  background: $color-blue;
  background: linear-gradient(90deg, $color-yellow 0%, $color-blue #{$gradient-marg}, $color-blue #{$gradient-marg--right}, $color-pink 100%);

  .page-grid__block {
    &:not(&--no-border) {
      border-top-color: $color-blue;
    }

    & + .page-grid__block:before {
      border-left-color: $color-blue;
    }
  }

  .mix-blend-bg {
    background-color: $color-blue;
  }

  a {
    text-decoration-color: $color-blue;
  }
}

body[data-bg-color="yellow"] {
  background: $color-yellow;
  background: linear-gradient(90deg, $color-red 0%, $color-yellow #{$gradient-marg}, $color-yellow #{$gradient-marg--right}, $color-pink 100%);

  .page-grid__block {
    &:not(&--no-border) {
      border-top-color: $color-yellow;
    }

    & + .page-grid__block:before {
      border-left-color: $color-yellow;
    }
  }

  .mix-blend-bg {
    background-color: $color-yellow;
  }

  a {
    text-decoration-color: $color-yellow;
  }
}

body[data-bg-color="orange"] {
  background: $color-orange;
  background: linear-gradient(90deg, $color-red 0%, $color-orange #{$gradient-marg}, $color-orange #{$gradient-marg--right}, $color-pink 100%);

  .page-grid__block {
    &:not(&--no-border) {
      border-top-color: $color-orange;
    }

    & + .page-grid__block:before {
      border-left-color: $color-orange;
    }
  }

  .mix-blend-bg {
    background-color: $color-orange;
  }

  a {
    text-decoration-color: $color-orange;
  }
}

body[data-bg-color="pink"] {
  background: $color-pink;
  background: linear-gradient(90deg, $color-yellow 0%, $color-pink #{$gradient-marg}, $color-pink #{$gradient-marg--right}, $color-blue 100%);

  .page-grid__block {
    &:not(&--no-border) {
      border-top-color: $color-pink;
    }

    & + .page-grid__block:before {
      border-left-color: $color-pink;
    }
  }

  .mix-blend-bg {
    background-color: $color-pink;
  }

  a {
    text-decoration-color: $color-pink;
  }
}

body[data-bg-color="green"] {
  background: $color-green;
  background: linear-gradient(90deg, $color-yellow 0%, $color-green #{$gradient-marg}, $color-green #{$gradient-marg--right}, $color-pink 100%);

  .page-grid__block {
    &:not(&--no-border) {
      border-top-color: $color-green;
    }

    & + .page-grid__block:before {
      border-left-color: $color-green;
    }
  }

  .mix-blend-bg {
    background-color: $color-green;
  }

  a {
    text-decoration-color: $color-green;
  }
}

body[data-bg-color="grey"] {
  background: $color-grey;
  background: linear-gradient(90deg, $color-yellow 0%, $color-grey #{$gradient-marg}, $color-grey #{$gradient-marg--right}, $color-pink 100%);

  .page-grid__block {
    &:not(&--no-border) {
      border-top-color: $color-grey;
    }

    & + .page-grid__block:before {
      border-left-color: $color-grey;
    }
  }

  .mix-blend-bg {
    background-color: $color-grey;
  }

  a {
    text-decoration-color: $color-grey;
  }
}

.mix-blend-bg {
  img {
    mix-blend-mode: multiply;
    filter: grayscale(100%);
  }
}


.paragraph-title {
  font-weight: bold;

  [data-bg-color="red"] & {
    color: $color-red;
  }

  [data-bg-color="blue"] & {
    color: $color-blue;
  }

  [data-bg-color="yellow"] & {
    color: $color-yellow;
  }

  [data-bg-color="orange"] & {
    color: $color-orange;
  }

  [data-bg-color="pink"] & {
    color: $color-pink;
  }

  [data-bg-color="green"] & {
    color: $color-green;
  }

  [data-bg-color="grey"] & {
    color: $color-grey;
  }
}

