[data-glide-slideshow] {

  .glide__track,
  .glide__slides,
  .glide__slide {
    width: 100%;
  }

  .glide__slide {
    margin: 0;
  }

  .glide__slide img {
    width: 100%;
  }
}

.iti-slideshow {
  display: flex;
  flex-direction: column;

  &__slide {
    display: flex;
    flex-direction: column;

    &--red {
      .iti-slideshow__img {
        background-color: $color-red;
      }

      a {
        text-decoration-color: $color-red;
      }
    }
    &--blue {
      .iti-slideshow__img {
        background-color: $color-blue;
      }

      a {
        text-decoration-color: $color-blue;
      }
    }
    &--pink {
      .iti-slideshow__img {
        background-color: $color-pink;
      }

      a {
        text-decoration-color: $color-pink;
      }
    }
    &--yellow {
      .iti-slideshow__img {
        background-color: $color-yellow;
      }

      a {
        text-decoration-color: $color-yellow;
      }
    }
    &--orange {
      .iti-slideshow__img {
        background-color: $color-orange;
      }

      a {
        text-decoration-color: $color-orange;
      }
    }
    &--green {
      .iti-slideshow__img {
        background-color: $color-green;
      }

      a {
        text-decoration-color: $color-green;
      }
    }
    &--grey {
      .iti-slideshow__img {
        background-color: $color-grey;
      }

      a {
        text-decoration-color: $color-grey;
      }
    }
  }

  &__text {
    order: 2;

    @media #{$breakpoint-md-up} {
      order: 0;
    }

    .page-grid__block--slideshow .iti-slideshow__slide & {
      margin-top: $block-gap*2;
      @media #{$breakpoint-md-up} {
        margin-top: 0;
      }
    }
  }

  &__img {
    .page-grid__block--slideshow .iti-slideshow__slide & {
      @media #{$breakpoint-md-up} {
        margin-top: $block-gap*2;
      }
    }

    img {
      mix-blend-mode: luminosity;
      object-fit: cover;
      object-position: center;
      height: calc(( (100vw - #{$gutter-size}*4*2 - #{$margin-container-mobile-vw}*2) / #{$grid-columns} * 10 - #{$gutter-size}*2) * 9 / 16 );
      filter: grayscale(100%);

      @media #{$breakpoint-lg-up} {
        height: calc(( (100vw - #{$gutter-size}*4*2 - #{$margin-container-vw}*2) / #{$grid-columns} * 8 - #{$gutter-size}*2) * 9 / 16 );
      }

      @media only screen and (min-width: 1921px) {
        height: calc(( (1920px - #{$gutter-size}*4*2 - (1920px * #{$margin-container-percent})*2) / #{$grid-columns} * 8 - #{$gutter-size}*2) * 9 / 16 );
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    order: 2;

    @media #{$breakpoint-md-up} {
      order: 0;
    }

    &-item {
      flex-basis: calc((100% - #{$gutter-size}*6) / 4);
      min-width: calc((100% - #{$gutter-size}*6) / 4);
      outline: 0;
      border: 0;
      padding-top: $block-gap;
      cursor: pointer;

      @media #{$breakpoint-md-up} {
        padding-top: 0;
        padding-bottom: $block-gap;
      }

      &-content {
        width: 100%;
        height: 100%;
        height: $border-colored-width--mobile;
        background-color: $color-grey;

        @media #{$breakpoint-sm-up} {
          height: $border-colored-width;
        }
      }

      &--red .iti-slideshow__nav-item-content {
        background-color: $color-red;
      }
      &--blue .iti-slideshow__nav-item-content {
        background-color: $color-blue;
      }
      &--pink .iti-slideshow__nav-item-content {
        background-color: $color-pink;
      }
      &--yellow .iti-slideshow__nav-item-content {
        background-color: $color-yellow;
      }
      &--orange .iti-slideshow__nav-item-content {
        background-color: $color-orange;
      }
      &--green .iti-slideshow__nav-item-content {
        background-color: $color-green;
      }
      &--grey .iti-slideshow__nav-item-content {
        background-color: $color-grey;
      }

      &:hover .iti-slideshow__nav-item-content {
        background-color: $color-grey-darker;
      }
    }
  }
}
