@font-face {
  font-family: 'Maison';
  src: url('../fonts/MaisonNeue-Book.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Maison';
  src: url('../fonts/MaisonNeue-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Maison';
  src: url('../fonts/MaisonNeue-Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Maison';
  src: url('../fonts/MaisonNeue-Medium.otf');
  font-weight: 500;
}
