.header {
  border-top: $border-colored-width--mobile solid $color-grey-darker;

  @media #{$breakpoint-sm-up} {
    border-top-width: $border-colored-width;
  }

  .social {
    margin-top: 1em;
  }

  ul {
    padding: 0;
  }
}

.navigation {
  padding: $block-gap;
  padding-top: $block-gap*2;

  * {
    font-size: $menu-font-size--mobile;
    text-transform: uppercase;
    line-height: 1.4;
    font-weight: 500;

    @media #{$breakpoint-sm-up} {
      font-size: $menu-font-size--tablet;
    }

    @media #{$breakpoint-md-up} {
      font-size: $menu-font-size;
    }
  }

  &__option-w-children,
  &__option {
    margin-top: 0.1em;
    a, span {
      padding-top: 0.2em;
      line-height: 1.1;
    }
  }

  ul ul {
    margin-left: 0.6em;
    @media #{$breakpoint-sm-up} {
      margin-left: 1em;
    }
  }

  a, span {
    display: inline-block;
    padding-left: calc(#{$block-gap} *0.5);
    padding-right: calc(#{$block-gap} *0.5);

    @media #{$breakpoint-md-up} {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}


.navigation__option {
  a.active,
  a:hover {
    text-decoration-line: none;
  }

  @media #{$breakpoint-md-up} {
    a.active[data-menu-color="blue"],
    a[data-menu-color="blue"]:hover {
      background-color: $color-blue;
    }

    a.active[data-menu-color="pink"],
    a[data-menu-color="pink"]:hover {
      background-color: $color-pink;
    }

    a.active[data-menu-color="yellow"],
    a[data-menu-color="yellow"]:hover {
      background-color: $color-yellow;
    }

    a.active[data-menu-color="orange"],
    a[data-menu-color="orange"]:hover {
      background-color: $color-orange;
    }

    a.active[data-menu-color="red"],
    a[data-menu-color="red"]:hover {
      background-color: $color-red;
    }

    a.active[data-menu-color="green"],
    a[data-menu-color="green"]:hover {
      background-color: $color-green;
    }

    a.active[data-menu-color="grey"],
    a[data-menu-color="grey"]:hover {
      background-color: $color-grey;
    }
  }
}

.logo {
  width: 100%;
  height: auto;
  padding-right: $block-gap/2;
}


.social {
  display: flex;

  &__icon {
    width: 1.4em;
    margin-right: 0.3em;
  }
}

.logo {
  padding-top: 0.25em;
  @media #{$breakpoint-md-up} {
    padding-top: 0;
    margin-top: 0.5em;
  }
}

//LOGO
polygon,
path,
rect,
line {
  stroke-width: 0.1px;
}



.first-load {
  .curve-bezier,
  .point {
    transition: all 0.1s linear;

    @media #{$breakpoint-md-up} {
      transition: all 0.1s linear;
    }
  }
}

.logo-svg--mobile {
  opacity: 1;

  &.first-load {
    opacity: 0;
  }
}




$menu-hamb-width: 3em;

.logo--mobile {
  position: sticky;
  top: 0px;
  padding-right: $gutter-size*4;
  padding-left: calc(#{$menu-hamb-width} + #{$gutter-size}*4);
  margin-bottom: -3em;
  max-width: 600px;

  @media #{$breakpoint-sm-up} {
    margin-bottom: -5em;
  }

  @media #{$breakpoint-md-up} {
    display: none;
  }
}

// .header-mobile-active {
//   height: calc(var(--window-inner-height) - 1px);
//   overflow: hidden;
//   box-sizing: border-box;
// }

.header-mobile {
  position: fixed;
  top: 0;
  left: $margin-container-mobile;
  right: $margin-container-mobile;
  background-color: white;
  display: none;
  z-index: $z-header-mobile;

  ul li a,
  ul li span {
    display: block;
  }

  .header-mobile-active & {
    display: block;
    @media #{$breakpoint-md-up} {
      display: none;
    }
  }

  @media #{$breakpoint-md-up} {
    display: none;
  }

  &--active-menu {
    &[data-bg-color="red"] {
      background-color: $color-red;

      ul ul li a.active,
      .navigation__option--open > a,
      .navigation__option--open > span {
        color: $color-red;
        background-color: white;
      }
    }

    &[data-bg-color="blue"] {
      background-color: $color-blue;

      ul ul li a.active,
      .navigation__option--open > a,
      .navigation__option--open > span {
        color: $color-blue;
        background-color: white;
      }
    }

    &[data-bg-color="yellow"] {
      background-color: $color-yellow;

      ul ul li a.active,
      .navigation__option--open > a,
      .navigation__option--open > span {
        color: $color-yellow;
        background-color: white;
      }
    }

    &[data-bg-color="orange"] {
      background-color: $color-orange;

      ul ul li a.active,
      .navigation__option--open > a,
      .navigation__option--open > span {
        color: $color-orange;
        background-color: white;
      }
    }

    &[data-bg-color="pink"] {
      background-color: $color-pink;

      ul ul li a.active,
      .navigation__option--open > a,
      .navigation__option--open > span {
        color: $color-pink;
        background-color: white;
      }
    }

    &[data-bg-color="green"] {
      background-color: $color-green;

      ul ul li a.active,
      .navigation__option--open > a,
      .navigation__option--open > span {
        color: $color-green;
        background-color: white;
      }
    }

    &[data-bg-color="grey"] {
      background-color: $color-grey;

      .navigation__option--open span {
        color: $color-grey;
        background-color: white;
      }
    }

    ul ul li a {
      color: white;
    }
  }

  .navigation {
    width: 100%;
    position: relative;
    padding: 0;
    margin-top: calc(#{$menu-hamb-width} + #{$gutter-size}*4);
    margin-bottom: calc(#{$gutter-size}*2);

    @media #{$breakpoint-sm-up} {
      margin-top: calc(#{$menu-hamb-width} + #{$gutter-size}*6);
      margin-bottom: calc(#{$gutter-size}*3);
    }
  }


  ul {
    padding: 0;
    width: 50%;

    ul {
      position: absolute;
      right: 0;
      top: 0;
      display: none;
    }
  }

  .navigation__option--open ul {
    display: block;
  }
}

.menu-hamb {
  width: $menu-hamb-width;
  position: fixed;
  z-index: 10;
  top: calc(#{$block-gap} *0.5);
  left: calc(#{$margin-container-mobile-vw} + #{$block-gap} *0.5);
  z-index: $z-menu-hamb;

  &--active {
    transform: rotate(90deg);
  }
}
