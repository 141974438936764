// 1. ABSTRACTS
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/fonts';

// 2. VENDORS
@import 'vendors';

// 3. VENDORS EXTENSIONS
// @import 'vendors-extensions/flexbox';

// 4. BASE
@import 'base/typography';
@import 'base/base';

// 5. LAYOUT
@import 'layout/grid';
@import 'layout/general';
@import 'layout/header';
@import 'layout/footer';

// 6. COMPONENTS
@import 'components/slideshow';
@import 'components/page-grid';
@import 'components/people';
@import 'components/numbers-grid';
@import 'components/logos-grid';
@import 'components/sub-section';
@import 'components/pub-list';


// 7. PAGES
// @import 'pages/index';
