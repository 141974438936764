*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

:where(body) {
  margin: 0;
}

:where(h1) {
  margin: .67em 0;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

:where(hr) {
  color: inherit;
  height: 0;
}

:where(nav) :where(ol, ul) {
  padding: 0;
  list-style-type: none;
}

:where(nav li):before {
  content: "​";
  float: left;
}

:where(pre) {
  font-family: monospace;
  font-size: 1em;
  overflow: auto;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

:where(iframe) {
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

:where(table) {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

:where(button, input, select) {
  margin: 0;
}

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

:where(progress) {
  vertical-align: baseline;
}

:where(textarea) {
  resize: vertical;
  margin: 0;
}

:where([type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(dialog) {
  color: #000;
  height: -moz-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fff;
  border: solid;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(details > summary:first-of-type) {
  display: list-item;
}

:where([aria-busy="true" i]) {
  cursor: progress;
}

:where([aria-controls]) {
  cursor: pointer;
}

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@font-face {
  font-family: Maison;
  src: url("MaisonNeue-Book.a72c219d.otf");
  font-weight: normal;
}

@font-face {
  font-family: Maison;
  src: url("MaisonNeue-Bold.754d37d1.otf");
  font-weight: bold;
}

@font-face {
  font-family: Maison;
  src: url("MaisonNeue-Light.f04859ff.otf");
  font-weight: 300;
}

@font-face {
  font-family: Maison;
  src: url("MaisonNeue-Medium.f09d28d5.otf");
  font-weight: 500;
}

.row {
  box-sizing: border-box;
  width: 100%;
  flex-flow: wrap;
  flex: 0 auto;
  margin: 0 auto;
  display: flex;
}

.row.reverse {
  flex-direction: row-reverse;
}

.row.natural-height {
  align-items: flex-start;
}

.row.flex-column {
  flex-direction: column;
}

.col {
  box-sizing: border-box;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 7.5px;
}

.col.reverse {
  flex-direction: column-reverse;
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

.col-xs {
  box-sizing: border-box;
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
  padding: 0 7.5px;
}

.col-xs-1 {
  box-sizing: border-box;
  max-width: 10%;
  flex-basis: 10%;
  padding: 0 7.5px;
}

.col-xs-offset-1 {
  margin-left: 10%;
}

.col-xs-2 {
  box-sizing: border-box;
  max-width: 20%;
  flex-basis: 20%;
  padding: 0 7.5px;
}

.col-xs-offset-2 {
  margin-left: 20%;
}

.col-xs-3 {
  box-sizing: border-box;
  max-width: 30%;
  flex-basis: 30%;
  padding: 0 7.5px;
}

.col-xs-offset-3 {
  margin-left: 30%;
}

.col-xs-4 {
  box-sizing: border-box;
  max-width: 40%;
  flex-basis: 40%;
  padding: 0 7.5px;
}

.col-xs-offset-4 {
  margin-left: 40%;
}

.col-xs-5 {
  box-sizing: border-box;
  max-width: 50%;
  flex-basis: 50%;
  padding: 0 7.5px;
}

.col-xs-offset-5 {
  margin-left: 50%;
}

.col-xs-6 {
  box-sizing: border-box;
  max-width: 60%;
  flex-basis: 60%;
  padding: 0 7.5px;
}

.col-xs-offset-6 {
  margin-left: 60%;
}

.col-xs-7 {
  box-sizing: border-box;
  max-width: 70%;
  flex-basis: 70%;
  padding: 0 7.5px;
}

.col-xs-offset-7 {
  margin-left: 70%;
}

.col-xs-8 {
  box-sizing: border-box;
  max-width: 80%;
  flex-basis: 80%;
  padding: 0 7.5px;
}

.col-xs-offset-8 {
  margin-left: 80%;
}

.col-xs-9 {
  box-sizing: border-box;
  max-width: 90%;
  flex-basis: 90%;
  padding: 0 7.5px;
}

.col-xs-offset-9 {
  margin-left: 90%;
}

.col-xs-10 {
  box-sizing: border-box;
  max-width: 100%;
  flex-basis: 100%;
  padding: 0 7.5px;
}

.col-xs-offset-10 {
  margin-left: 100%;
}

.row.start-xs {
  justify-content: flex-start;
}

.row.center-xs {
  justify-content: center;
}

.row.end-xs {
  justify-content: flex-end;
}

.row.top-xs {
  align-items: flex-start;
}

.row.middle-xs {
  align-items: center;
}

.row.bottom-xs {
  align-items: flex-end;
}

.row.around-xs {
  justify-content: space-around;
}

.row.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 641px) {
  .col-sm {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 7.5px;
  }

  .col-sm-1 {
    box-sizing: border-box;
    max-width: 10%;
    flex-basis: 10%;
    padding: 0 7.5px;
  }

  .col-sm-offset-1 {
    margin-left: 10%;
  }

  .col-sm-2 {
    box-sizing: border-box;
    max-width: 20%;
    flex-basis: 20%;
    padding: 0 7.5px;
  }

  .col-sm-offset-2 {
    margin-left: 20%;
  }

  .col-sm-3 {
    box-sizing: border-box;
    max-width: 30%;
    flex-basis: 30%;
    padding: 0 7.5px;
  }

  .col-sm-offset-3 {
    margin-left: 30%;
  }

  .col-sm-4 {
    box-sizing: border-box;
    max-width: 40%;
    flex-basis: 40%;
    padding: 0 7.5px;
  }

  .col-sm-offset-4 {
    margin-left: 40%;
  }

  .col-sm-5 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0 7.5px;
  }

  .col-sm-offset-5 {
    margin-left: 50%;
  }

  .col-sm-6 {
    box-sizing: border-box;
    max-width: 60%;
    flex-basis: 60%;
    padding: 0 7.5px;
  }

  .col-sm-offset-6 {
    margin-left: 60%;
  }

  .col-sm-7 {
    box-sizing: border-box;
    max-width: 70%;
    flex-basis: 70%;
    padding: 0 7.5px;
  }

  .col-sm-offset-7 {
    margin-left: 70%;
  }

  .col-sm-8 {
    box-sizing: border-box;
    max-width: 80%;
    flex-basis: 80%;
    padding: 0 7.5px;
  }

  .col-sm-offset-8 {
    margin-left: 80%;
  }

  .col-sm-9 {
    box-sizing: border-box;
    max-width: 90%;
    flex-basis: 90%;
    padding: 0 7.5px;
  }

  .col-sm-offset-9 {
    margin-left: 90%;
  }

  .col-sm-10 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0 7.5px;
  }

  .col-sm-offset-10 {
    margin-left: 100%;
  }

  .row.start-sm {
    justify-content: flex-start;
  }

  .row.center-sm {
    justify-content: center;
  }

  .row.end-sm {
    justify-content: flex-end;
  }

  .row.top-sm {
    align-items: flex-start;
  }

  .row.middle-sm {
    align-items: center;
  }

  .row.bottom-sm {
    align-items: flex-end;
  }

  .row.around-sm {
    justify-content: space-around;
  }

  .row.between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 7.5px;
  }

  .col-md-1 {
    box-sizing: border-box;
    max-width: 10%;
    flex-basis: 10%;
    padding: 0 7.5px;
  }

  .col-md-offset-1 {
    margin-left: 10%;
  }

  .col-md-2 {
    box-sizing: border-box;
    max-width: 20%;
    flex-basis: 20%;
    padding: 0 7.5px;
  }

  .col-md-offset-2 {
    margin-left: 20%;
  }

  .col-md-3 {
    box-sizing: border-box;
    max-width: 30%;
    flex-basis: 30%;
    padding: 0 7.5px;
  }

  .col-md-offset-3 {
    margin-left: 30%;
  }

  .col-md-4 {
    box-sizing: border-box;
    max-width: 40%;
    flex-basis: 40%;
    padding: 0 7.5px;
  }

  .col-md-offset-4 {
    margin-left: 40%;
  }

  .col-md-5 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0 7.5px;
  }

  .col-md-offset-5 {
    margin-left: 50%;
  }

  .col-md-6 {
    box-sizing: border-box;
    max-width: 60%;
    flex-basis: 60%;
    padding: 0 7.5px;
  }

  .col-md-offset-6 {
    margin-left: 60%;
  }

  .col-md-7 {
    box-sizing: border-box;
    max-width: 70%;
    flex-basis: 70%;
    padding: 0 7.5px;
  }

  .col-md-offset-7 {
    margin-left: 70%;
  }

  .col-md-8 {
    box-sizing: border-box;
    max-width: 80%;
    flex-basis: 80%;
    padding: 0 7.5px;
  }

  .col-md-offset-8 {
    margin-left: 80%;
  }

  .col-md-9 {
    box-sizing: border-box;
    max-width: 90%;
    flex-basis: 90%;
    padding: 0 7.5px;
  }

  .col-md-offset-9 {
    margin-left: 90%;
  }

  .col-md-10 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0 7.5px;
  }

  .col-md-offset-10 {
    margin-left: 100%;
  }

  .row.start-md {
    justify-content: flex-start;
  }

  .row.center-md {
    justify-content: center;
  }

  .row.end-md {
    justify-content: flex-end;
  }

  .row.top-md {
    align-items: flex-start;
  }

  .row.middle-md {
    align-items: center;
  }

  .row.bottom-md {
    align-items: flex-end;
  }

  .row.around-md {
    justify-content: space-around;
  }

  .row.between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 7.5px;
  }

  .col-lg-1 {
    box-sizing: border-box;
    max-width: 10%;
    flex-basis: 10%;
    padding: 0 7.5px;
  }

  .col-lg-offset-1 {
    margin-left: 10%;
  }

  .col-lg-2 {
    box-sizing: border-box;
    max-width: 20%;
    flex-basis: 20%;
    padding: 0 7.5px;
  }

  .col-lg-offset-2 {
    margin-left: 20%;
  }

  .col-lg-3 {
    box-sizing: border-box;
    max-width: 30%;
    flex-basis: 30%;
    padding: 0 7.5px;
  }

  .col-lg-offset-3 {
    margin-left: 30%;
  }

  .col-lg-4 {
    box-sizing: border-box;
    max-width: 40%;
    flex-basis: 40%;
    padding: 0 7.5px;
  }

  .col-lg-offset-4 {
    margin-left: 40%;
  }

  .col-lg-5 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0 7.5px;
  }

  .col-lg-offset-5 {
    margin-left: 50%;
  }

  .col-lg-6 {
    box-sizing: border-box;
    max-width: 60%;
    flex-basis: 60%;
    padding: 0 7.5px;
  }

  .col-lg-offset-6 {
    margin-left: 60%;
  }

  .col-lg-7 {
    box-sizing: border-box;
    max-width: 70%;
    flex-basis: 70%;
    padding: 0 7.5px;
  }

  .col-lg-offset-7 {
    margin-left: 70%;
  }

  .col-lg-8 {
    box-sizing: border-box;
    max-width: 80%;
    flex-basis: 80%;
    padding: 0 7.5px;
  }

  .col-lg-offset-8 {
    margin-left: 80%;
  }

  .col-lg-9 {
    box-sizing: border-box;
    max-width: 90%;
    flex-basis: 90%;
    padding: 0 7.5px;
  }

  .col-lg-offset-9 {
    margin-left: 90%;
  }

  .col-lg-10 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0 7.5px;
  }

  .col-lg-offset-10 {
    margin-left: 100%;
  }

  .row.start-lg {
    justify-content: flex-start;
  }

  .row.center-lg {
    justify-content: center;
  }

  .row.end-lg {
    justify-content: flex-end;
  }

  .row.top-lg {
    align-items: flex-start;
  }

  .row.middle-lg {
    align-items: center;
  }

  .row.bottom-lg {
    align-items: flex-end;
  }

  .row.around-lg {
    justify-content: space-around;
  }

  .row.between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl {
    box-sizing: border-box;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 7.5px;
  }

  .col-xl-1 {
    box-sizing: border-box;
    max-width: 10%;
    flex-basis: 10%;
    padding: 0 7.5px;
  }

  .col-xl-offset-1 {
    margin-left: 10%;
  }

  .col-xl-2 {
    box-sizing: border-box;
    max-width: 20%;
    flex-basis: 20%;
    padding: 0 7.5px;
  }

  .col-xl-offset-2 {
    margin-left: 20%;
  }

  .col-xl-3 {
    box-sizing: border-box;
    max-width: 30%;
    flex-basis: 30%;
    padding: 0 7.5px;
  }

  .col-xl-offset-3 {
    margin-left: 30%;
  }

  .col-xl-4 {
    box-sizing: border-box;
    max-width: 40%;
    flex-basis: 40%;
    padding: 0 7.5px;
  }

  .col-xl-offset-4 {
    margin-left: 40%;
  }

  .col-xl-5 {
    box-sizing: border-box;
    max-width: 50%;
    flex-basis: 50%;
    padding: 0 7.5px;
  }

  .col-xl-offset-5 {
    margin-left: 50%;
  }

  .col-xl-6 {
    box-sizing: border-box;
    max-width: 60%;
    flex-basis: 60%;
    padding: 0 7.5px;
  }

  .col-xl-offset-6 {
    margin-left: 60%;
  }

  .col-xl-7 {
    box-sizing: border-box;
    max-width: 70%;
    flex-basis: 70%;
    padding: 0 7.5px;
  }

  .col-xl-offset-7 {
    margin-left: 70%;
  }

  .col-xl-8 {
    box-sizing: border-box;
    max-width: 80%;
    flex-basis: 80%;
    padding: 0 7.5px;
  }

  .col-xl-offset-8 {
    margin-left: 80%;
  }

  .col-xl-9 {
    box-sizing: border-box;
    max-width: 90%;
    flex-basis: 90%;
    padding: 0 7.5px;
  }

  .col-xl-offset-9 {
    margin-left: 90%;
  }

  .col-xl-10 {
    box-sizing: border-box;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0 7.5px;
  }

  .col-xl-offset-10 {
    margin-left: 100%;
  }

  .row.start-xl {
    justify-content: flex-start;
  }

  .row.center-xl {
    justify-content: center;
  }

  .row.end-xl {
    justify-content: flex-end;
  }

  .row.top-xl {
    align-items: flex-start;
  }

  .row.middle-xl {
    align-items: center;
  }

  .row.bottom-xl {
    align-items: flex-end;
  }

  .row.around-xl {
    justify-content: space-around;
  }

  .row.between-xl {
    justify-content: space-between;
  }

  .first-xl {
    order: -1;
  }

  .last-xl {
    order: 1;
  }
}

.col-gutter-lr {
  padding: 0 0 7.5px;
}

.col-no-gutter {
  padding: 0;
}

.show {
  display: block !important;
}

.row.show {
  display: flex !important;
}

.hide {
  display: none !important;
}

.show-xs {
  display: block !important;
}

.row.show-xs {
  display: flex !important;
}

.hide-xs {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .show-xs-only {
    display: block !important;
  }

  .row.show-xs-only {
    display: flex !important;
  }

  .hide-xs-only {
    display: none !important;
  }
}

@media only screen and (min-width: 641px) {
  .show-sm {
    display: block !important;
  }

  .row.show-sm {
    display: flex !important;
  }

  .hide-sm {
    display: none !important;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1023px) {
  .show-sm-only {
    display: block !important;
  }

  .row.show-sm-only {
    display: flex !important;
  }

  .hide-sm-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .show-md {
    display: block !important;
  }

  .row.show-md {
    display: flex !important;
  }

  .hide-md {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .show-md-only {
    display: block !important;
  }

  .row.show-md-only {
    display: flex !important;
  }

  .hide-md-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1440px) {
  .show-lg {
    display: block !important;
  }

  .row.show-lg {
    display: flex !important;
  }

  .hide-lg {
    display: none !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1790px) {
  .show-lg-only {
    display: block !important;
  }

  .row.show-lg-only {
    display: flex !important;
  }

  .hide-lg-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1791px) {
  .show-xl {
    display: block !important;
  }

  .row.show-xl {
    display: flex !important;
  }

  .hide-xl {
    display: none !important;
  }
}

.glide {
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  width: 100%;
  height: 100%;
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  z-index: 2;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 .25em .5em #0000001a;
  opacity: 1;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #ffffff80;
  border-radius: 4px;
  padding: 9px 12px;
  line-height: 1;
  transition: opacity .15s, border .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__arrow:focus {
  outline: none;
}

.glide__arrow:hover {
  border-color: #fff;
}

.glide__arrow--left {
  left: 2em;
}

.glide__arrow--right {
  right: 2em;
}

.glide__arrow--disabled {
  opacity: .33;
}

.glide__bullets {
  z-index: 2;
  list-style: none;
  display: inline-flex;
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
}

.glide__bullet {
  width: 9px;
  height: 9px;
  cursor: pointer;
  background-color: #ffffff80;
  border: 2px solid #0000;
  border-radius: 50%;
  margin: 0 .25em;
  padding: 0;
  line-height: 0;
  transition: all .3s ease-in-out;
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__bullet:focus {
  outline: none;
}

.glide__bullet:hover, .glide__bullet:focus {
  background-color: #ffffff80;
  border: 2px solid #fff;
}

.glide__bullet--active {
  background-color: #fff;
}

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

html {
  font-size: 16.5px;
}

@media only screen and (min-width: 641px) {
  html {
    font-size: 16px;
  }
}

* {
  text-rendering: optimizelegibility;
  font-smoothing: antialiased;
  font-family: Maison, sans-serif;
  font-size: 1rem;
  line-height: 1.4;
}

p, h1, h2, h3, h4, h5, h6, ul, ol, li {
  margin: 0;
}

a {
  color: initial;
  text-underline-offset: .2em;
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

a:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

h1, h1 * {
  font-size: 1.35rem;
  font-weight: bold;
  line-height: 1.2;
}

h1 a, h1 * a {
  text-decoration-thickness: 3px;
}

@media only screen and (min-width: 641px) {
  h1 a, h1 * a {
    text-decoration-thickness: 5px;
  }

  h1, h1 * {
    font-size: 2.24rem;
  }
}

h2, h2 * {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
}

@media only screen and (min-width: 641px) {
  h2, h2 * {
    font-size: 1.39rem;
  }
}

h2 a, h2 * a {
  text-decoration-thickness: 3px;
}

@media only screen and (min-width: 641px) {
  h2 a, h2 * a {
    text-decoration-thickness: 5px;
  }
}

h3, h3 * {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.2;
}

@media only screen and (min-width: 641px) {
  h3, h3 * {
    font-size: 1.39rem;
  }
}

[data-bg-color="red"] h3, [data-bg-color="red"] h3 * {
  color: #ff4b64;
}

[data-bg-color="blue"] h3, [data-bg-color="blue"] h3 * {
  color: #237dd2;
}

[data-bg-color="yellow"] h3, [data-bg-color="yellow"] h3 * {
  color: #f0e678;
}

[data-bg-color="orange"] h3, [data-bg-color="orange"] h3 * {
  color: #e26956;
}

[data-bg-color="pink"] h3, [data-bg-color="pink"] h3 * {
  color: #e1b0bd;
}

[data-bg-color="green"] h3, [data-bg-color="green"] h3 * {
  color: #54c8a0;
}

[data-bg-color="grey"] h3, [data-bg-color="grey"] h3 * {
  color: #c8c8c8;
}

h4, h4 * {
  font-size: 1.12rem;
  font-weight: bold;
  line-height: 1.2;
}

@media only screen and (min-width: 641px) {
  h4, h4 * {
    font-size: 1.27rem;
  }
}

[data-bg-color="red"] h4, [data-bg-color="red"] h4 * {
  color: #ff4b64;
}

[data-bg-color="blue"] h4, [data-bg-color="blue"] h4 * {
  color: #237dd2;
}

[data-bg-color="yellow"] h4, [data-bg-color="yellow"] h4 * {
  color: #f0e678;
}

[data-bg-color="orange"] h4, [data-bg-color="orange"] h4 * {
  color: #e26956;
}

[data-bg-color="pink"] h4, [data-bg-color="pink"] h4 * {
  color: #e1b0bd;
}

[data-bg-color="green"] h4, [data-bg-color="green"] h4 * {
  color: #54c8a0;
}

[data-bg-color="grey"] h4, [data-bg-color="grey"] h4 * {
  color: #c8c8c8;
}

h5, h5 * {
  font-size: 1.12rem;
  font-weight: 500;
  line-height: 1.2;
}

@media only screen and (min-width: 641px) {
  h5, h5 * {
    font-size: 1.27rem;
  }
}

h6, h6 * {
  font-size: 1.12rem;
  font-weight: 300;
  line-height: 1.2;
}

@media only screen and (min-width: 641px) {
  h6, h6 * {
    font-size: 1.27rem;
  }
}

p.p--small, p.p--small *, ul.p--small, ul.p--small *, ol.p--small, ol.p--small * {
  font-size: .93rem;
}

@media only screen and (min-width: 641px) {
  p.p--small, p.p--small *, ul.p--small, ul.p--small *, ol.p--small, ol.p--small * {
    font-size: .85rem;
  }
}

.p--smaller, .footer, .footer *, .p--smaller * {
  font-size: .87rem;
}

@media only screen and (min-width: 641px) {
  .p--smaller, .footer, .footer *, .p--smaller * {
    font-size: .73rem;
  }
}

.text--tag {
  text-transform: uppercase;
  vertical-align: middle;
  margin-left: .5em;
  font-size: .7em;
  font-weight: bold;
}

[data-bg-color="red"] .text--tag {
  color: #ff4b64;
}

[data-bg-color="blue"] .text--tag {
  color: #237dd2;
}

[data-bg-color="yellow"] .text--tag {
  color: #f0e678;
}

[data-bg-color="orange"] .text--tag {
  color: #e26956;
}

[data-bg-color="pink"] .text--tag {
  color: #e1b0bd;
}

[data-bg-color="green"] .text--tag {
  color: #54c8a0;
}

[data-bg-color="grey"] .text--tag {
  color: #c8c8c8;
}

ul, ol {
  margin: 0;
  padding-left: 0;
}

ul {
  padding-left: .9em;
}

textarea, input, select {
  width: 100%;
  min-width: 150px;
  border: 1px solid #c8c8c8;
  border-radius: 0;
  outline: 0;
  margin-bottom: .5em;
  padding: .5em .3em;
  font-weight: 300;
}

[data-bg-color="red"] textarea, [data-bg-color="red"] input, [data-bg-color="red"] select {
  border-color: #ff4b64;
}

[data-bg-color="blue"] textarea, [data-bg-color="blue"] input, [data-bg-color="blue"] select {
  border-color: #237dd2;
}

[data-bg-color="yellow"] textarea, [data-bg-color="yellow"] input, [data-bg-color="yellow"] select {
  border-color: #f0e678;
}

[data-bg-color="orange"] textarea, [data-bg-color="orange"] input, [data-bg-color="orange"] select {
  border-color: #e26956;
}

[data-bg-color="pink"] textarea, [data-bg-color="pink"] input, [data-bg-color="pink"] select {
  border-color: #e1b0bd;
}

[data-bg-color="green"] textarea, [data-bg-color="green"] input, [data-bg-color="green"] select {
  border-color: #54c8a0;
}

[data-bg-color="grey"] textarea, [data-bg-color="grey"] input, [data-bg-color="grey"] select {
  border-color: #c8c8c8;
}

textarea {
  min-height: 10em;
}

@media only screen and (min-width: 641px) {
  select {
    width: 150px;
  }
}

input[type="submit"] {
  width: auto;
  border: 0;
  margin-left: auto;
  margin-right: 0;
  padding: 1em .3em .8em;
  line-height: .8;
  display: block;
}

[data-bg-color="red"] input[type="submit"] {
  background-color: #ff4b64;
}

[data-bg-color="blue"] input[type="submit"] {
  background-color: #237dd2;
}

[data-bg-color="yellow"] input[type="submit"] {
  background-color: #f0e678;
}

[data-bg-color="orange"] input[type="submit"] {
  background-color: #e26956;
}

[data-bg-color="pink"] input[type="submit"] {
  background-color: #e1b0bd;
}

[data-bg-color="green"] input[type="submit"] {
  background-color: #54c8a0;
}

[data-bg-color="grey"] input[type="submit"] {
  background-color: #c8c8c8;
}

input[type="submit"]:hover {
  cursor: pointer;
  color: #fff;
  background-color: #000;
}

.column-test {
  min-height: 200px;
  background-color: #ff00001a;
}

body {
  background: linear-gradient(90deg, #f0e678 0%, #237dd2 5% 95%, #e1b0bd 100%);
}

@media only screen and (min-width: 1440px) {
  body {
    background: linear-gradient(90deg, #f0e678 0%, #237dd2 8% 92%, #e1b0bd 100%);
  }
}

.body-container {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

.body-content {
  max-width: 1920px;
  background-color: #fff;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 0;
}

@media only screen and (min-width: 641px) {
  .body-content {
    padding: 0 7.5px 7.5px;
  }
}

@media only screen and (min-width: 1024px) {
  .body-content {
    padding: 30px 15px 15px;
    display: flex;
  }
}

@media only screen and (min-width: 1440px) {
  .body-content {
    margin-left: 10%;
    margin-right: 10%;
  }
}

.body-content__header {
  flex-basis: 20%;
}

.body-content__body-page {
  min-height: calc(100vh - 60px);
  background-color: #fff;
  flex-basis: 100%;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .body-content__body-page {
    flex-basis: 80%;
  }
}

.link__more {
  text-transform: uppercase;
  text-underline-offset: .2em;
  margin-left: .5em;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-color: #237dd2;
  text-decoration-color: #237dd2;
  text-decoration-thickness: .15em;
  font-size: max(.6em, 10px) !important;
}

body[data-bg-color="red"] {
  background: linear-gradient(90deg, #f0e678 0%, #ff4b64 8% 92%, #e1b0bd 100%);
}

body[data-bg-color="red"] .page-grid__block:not(body[data-bg-color="red"] .page-grid__block--no-border) {
  border-top-color: #ff4b64;
}

body[data-bg-color="red"] .page-grid__block + .page-grid__block:before {
  border-left-color: #ff4b64;
}

body[data-bg-color="red"] .mix-blend-bg {
  background-color: #ff4b64;
}

body[data-bg-color="red"] a {
  -webkit-text-decoration-color: #ff4b64;
  text-decoration-color: #ff4b64;
}

body[data-bg-color="blue"] {
  background: linear-gradient(90deg, #f0e678 0%, #237dd2 8% 92%, #e1b0bd 100%);
}

body[data-bg-color="blue"] .page-grid__block:not(body[data-bg-color="blue"] .page-grid__block--no-border) {
  border-top-color: #237dd2;
}

body[data-bg-color="blue"] .page-grid__block + .page-grid__block:before {
  border-left-color: #237dd2;
}

body[data-bg-color="blue"] .mix-blend-bg {
  background-color: #237dd2;
}

body[data-bg-color="blue"] a {
  -webkit-text-decoration-color: #237dd2;
  text-decoration-color: #237dd2;
}

body[data-bg-color="yellow"] {
  background: linear-gradient(90deg, #ff4b64 0%, #f0e678 8% 92%, #e1b0bd 100%);
}

body[data-bg-color="yellow"] .page-grid__block:not(body[data-bg-color="yellow"] .page-grid__block--no-border) {
  border-top-color: #f0e678;
}

body[data-bg-color="yellow"] .page-grid__block + .page-grid__block:before {
  border-left-color: #f0e678;
}

body[data-bg-color="yellow"] .mix-blend-bg {
  background-color: #f0e678;
}

body[data-bg-color="yellow"] a {
  -webkit-text-decoration-color: #f0e678;
  text-decoration-color: #f0e678;
}

body[data-bg-color="orange"] {
  background: linear-gradient(90deg, #ff4b64 0%, #e26956 8% 92%, #e1b0bd 100%);
}

body[data-bg-color="orange"] .page-grid__block:not(body[data-bg-color="orange"] .page-grid__block--no-border) {
  border-top-color: #e26956;
}

body[data-bg-color="orange"] .page-grid__block + .page-grid__block:before {
  border-left-color: #e26956;
}

body[data-bg-color="orange"] .mix-blend-bg {
  background-color: #e26956;
}

body[data-bg-color="orange"] a {
  -webkit-text-decoration-color: #e26956;
  text-decoration-color: #e26956;
}

body[data-bg-color="pink"] {
  background: linear-gradient(90deg, #f0e678 0%, #e1b0bd 8% 92%, #237dd2 100%);
}

body[data-bg-color="pink"] .page-grid__block:not(body[data-bg-color="pink"] .page-grid__block--no-border) {
  border-top-color: #e1b0bd;
}

body[data-bg-color="pink"] .page-grid__block + .page-grid__block:before {
  border-left-color: #e1b0bd;
}

body[data-bg-color="pink"] .mix-blend-bg {
  background-color: #e1b0bd;
}

body[data-bg-color="pink"] a {
  -webkit-text-decoration-color: #e1b0bd;
  text-decoration-color: #e1b0bd;
}

body[data-bg-color="green"] {
  background: linear-gradient(90deg, #f0e678 0%, #54c8a0 8% 92%, #e1b0bd 100%);
}

body[data-bg-color="green"] .page-grid__block:not(body[data-bg-color="green"] .page-grid__block--no-border) {
  border-top-color: #54c8a0;
}

body[data-bg-color="green"] .page-grid__block + .page-grid__block:before {
  border-left-color: #54c8a0;
}

body[data-bg-color="green"] .mix-blend-bg {
  background-color: #54c8a0;
}

body[data-bg-color="green"] a {
  -webkit-text-decoration-color: #54c8a0;
  text-decoration-color: #54c8a0;
}

body[data-bg-color="grey"] {
  background: linear-gradient(90deg, #f0e678 0%, #c8c8c8 8% 92%, #e1b0bd 100%);
}

body[data-bg-color="grey"] .page-grid__block:not(body[data-bg-color="grey"] .page-grid__block--no-border) {
  border-top-color: #c8c8c8;
}

body[data-bg-color="grey"] .page-grid__block + .page-grid__block:before {
  border-left-color: #c8c8c8;
}

body[data-bg-color="grey"] .mix-blend-bg {
  background-color: #c8c8c8;
}

body[data-bg-color="grey"] a {
  -webkit-text-decoration-color: #c8c8c8;
  text-decoration-color: #c8c8c8;
}

.mix-blend-bg img {
  mix-blend-mode: multiply;
  filter: grayscale();
}

.paragraph-title {
  font-weight: bold;
}

[data-bg-color="red"] .paragraph-title {
  color: #ff4b64;
}

[data-bg-color="blue"] .paragraph-title {
  color: #237dd2;
}

[data-bg-color="yellow"] .paragraph-title {
  color: #f0e678;
}

[data-bg-color="orange"] .paragraph-title {
  color: #e26956;
}

[data-bg-color="pink"] .paragraph-title {
  color: #e1b0bd;
}

[data-bg-color="green"] .paragraph-title {
  color: #54c8a0;
}

[data-bg-color="grey"] .paragraph-title {
  color: #c8c8c8;
}

.header {
  border-top: 3px solid #646464;
}

@media only screen and (min-width: 641px) {
  .header {
    border-top-width: 5px;
  }
}

.header .social {
  margin-top: 1em;
}

.header ul {
  padding: 0;
}

.navigation {
  padding: 30px 15px 15px;
}

.navigation * {
  text-transform: uppercase;
  font-size: .97rem;
  font-weight: 500;
  line-height: 1.4;
}

@media only screen and (min-width: 641px) {
  .navigation * {
    font-size: 1.35rem;
  }
}

@media only screen and (min-width: 1024px) {
  .navigation * {
    font-size: .97rem;
  }
}

.navigation__option-w-children, .navigation__option {
  margin-top: .1em;
}

.navigation__option-w-children a, .navigation__option-w-children span, .navigation__option a, .navigation__option span {
  padding-top: .2em;
  line-height: 1.1;
}

.navigation ul ul {
  margin-left: .6em;
}

@media only screen and (min-width: 641px) {
  .navigation ul ul {
    margin-left: 1em;
  }
}

.navigation a, .navigation span {
  padding-left: 7.5px;
  padding-right: 7.5px;
  display: inline-block;
}

@media only screen and (min-width: 1024px) {
  .navigation a, .navigation span {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.navigation__option a.active, .navigation__option a:hover {
  -webkit-text-decoration-line: none;
  text-decoration-line: none;
}

@media only screen and (min-width: 1024px) {
  .navigation__option a.active[data-menu-color="blue"], .navigation__option a[data-menu-color="blue"]:hover {
    background-color: #237dd2;
  }

  .navigation__option a.active[data-menu-color="pink"], .navigation__option a[data-menu-color="pink"]:hover {
    background-color: #e1b0bd;
  }

  .navigation__option a.active[data-menu-color="yellow"], .navigation__option a[data-menu-color="yellow"]:hover {
    background-color: #f0e678;
  }

  .navigation__option a.active[data-menu-color="orange"], .navigation__option a[data-menu-color="orange"]:hover {
    background-color: #e26956;
  }

  .navigation__option a.active[data-menu-color="red"], .navigation__option a[data-menu-color="red"]:hover {
    background-color: #ff4b64;
  }

  .navigation__option a.active[data-menu-color="green"], .navigation__option a[data-menu-color="green"]:hover {
    background-color: #54c8a0;
  }

  .navigation__option a.active[data-menu-color="grey"], .navigation__option a[data-menu-color="grey"]:hover {
    background-color: #c8c8c8;
  }
}

.logo {
  width: 100%;
  height: auto;
  padding-right: 7.5px;
}

.social {
  display: flex;
}

.social__icon {
  width: 1.4em;
  margin-right: .3em;
}

.logo {
  padding-top: .25em;
}

@media only screen and (min-width: 1024px) {
  .logo {
    margin-top: .5em;
    padding-top: 0;
  }
}

polygon, path, rect, line {
  stroke-width: .1px;
}

.first-load .curve-bezier, .first-load .point {
  transition: all .1s linear;
}

@media only screen and (min-width: 1024px) {
  .first-load .curve-bezier, .first-load .point {
    transition: all .1s linear;
  }
}

.logo-svg--mobile {
  opacity: 1;
}

.logo-svg--mobile.first-load {
  opacity: 0;
}

.logo--mobile {
  max-width: 600px;
  margin-bottom: -3em;
  padding-left: calc(3em + 30px);
  padding-right: 30px;
  position: sticky;
  top: 0;
}

@media only screen and (min-width: 641px) {
  .logo--mobile {
    margin-bottom: -5em;
  }
}

@media only screen and (min-width: 1024px) {
  .logo--mobile {
    display: none;
  }
}

.header-mobile {
  z-index: 10;
  background-color: #fff;
  display: none;
  position: fixed;
  top: 0;
  left: 5%;
  right: 5%;
}

.header-mobile ul li a, .header-mobile ul li span, .header-mobile-active .header-mobile {
  display: block;
}

@media only screen and (min-width: 1024px) {
  .header-mobile-active .header-mobile, .header-mobile {
    display: none;
  }
}

.header-mobile--active-menu[data-bg-color="red"] {
  background-color: #ff4b64;
}

.header-mobile--active-menu[data-bg-color="red"] ul ul li a.active, .header-mobile--active-menu[data-bg-color="red"] .navigation__option--open > a, .header-mobile--active-menu[data-bg-color="red"] .navigation__option--open > span {
  color: #ff4b64;
  background-color: #fff;
}

.header-mobile--active-menu[data-bg-color="blue"] {
  background-color: #237dd2;
}

.header-mobile--active-menu[data-bg-color="blue"] ul ul li a.active, .header-mobile--active-menu[data-bg-color="blue"] .navigation__option--open > a, .header-mobile--active-menu[data-bg-color="blue"] .navigation__option--open > span {
  color: #237dd2;
  background-color: #fff;
}

.header-mobile--active-menu[data-bg-color="yellow"] {
  background-color: #f0e678;
}

.header-mobile--active-menu[data-bg-color="yellow"] ul ul li a.active, .header-mobile--active-menu[data-bg-color="yellow"] .navigation__option--open > a, .header-mobile--active-menu[data-bg-color="yellow"] .navigation__option--open > span {
  color: #f0e678;
  background-color: #fff;
}

.header-mobile--active-menu[data-bg-color="orange"] {
  background-color: #e26956;
}

.header-mobile--active-menu[data-bg-color="orange"] ul ul li a.active, .header-mobile--active-menu[data-bg-color="orange"] .navigation__option--open > a, .header-mobile--active-menu[data-bg-color="orange"] .navigation__option--open > span {
  color: #e26956;
  background-color: #fff;
}

.header-mobile--active-menu[data-bg-color="pink"] {
  background-color: #e1b0bd;
}

.header-mobile--active-menu[data-bg-color="pink"] ul ul li a.active, .header-mobile--active-menu[data-bg-color="pink"] .navigation__option--open > a, .header-mobile--active-menu[data-bg-color="pink"] .navigation__option--open > span {
  color: #e1b0bd;
  background-color: #fff;
}

.header-mobile--active-menu[data-bg-color="green"] {
  background-color: #54c8a0;
}

.header-mobile--active-menu[data-bg-color="green"] ul ul li a.active, .header-mobile--active-menu[data-bg-color="green"] .navigation__option--open > a, .header-mobile--active-menu[data-bg-color="green"] .navigation__option--open > span {
  color: #54c8a0;
  background-color: #fff;
}

.header-mobile--active-menu[data-bg-color="grey"] {
  background-color: #c8c8c8;
}

.header-mobile--active-menu[data-bg-color="grey"] .navigation__option--open span {
  color: #c8c8c8;
  background-color: #fff;
}

.header-mobile--active-menu ul ul li a {
  color: #fff;
}

.header-mobile .navigation {
  width: 100%;
  margin-top: calc(3em + 30px);
  margin-bottom: 15px;
  padding: 0;
  position: relative;
}

@media only screen and (min-width: 641px) {
  .header-mobile .navigation {
    margin-top: calc(3em + 45px);
    margin-bottom: 22.5px;
  }
}

.header-mobile ul {
  width: 50%;
  padding: 0;
}

.header-mobile ul ul {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.header-mobile .navigation__option--open ul {
  display: block;
}

.menu-hamb {
  width: 3em;
  z-index: 10;
  z-index: 11;
  position: fixed;
  top: 7.5px;
  left: calc(5vw + 7.5px);
}

.menu-hamb--active {
  transform: rotate(90deg);
}

.footer {
  border-top: 3px solid #000;
}

@media only screen and (min-width: 641px) {
  .footer {
    border-top-width: 5px;
  }
}

.footer, .footer * {
  line-height: 1.3;
}

.footer__group {
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10.5px 7.5px 15px;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .footer__group {
    flex-wrap: no-wrap;
  }
}

.footer__group + .footer__group {
  border-top: 2px solid #000;
}

.footer__group > * {
  flex-grow: 0;
  flex-basis: 100%;
  margin-bottom: 1.3em;
}

@media only screen and (min-width: 641px) {
  .footer__group > * {
    flex-basis: 70%;
  }
}

@media only screen and (min-width: 1024px) {
  .footer__group > * {
    flex-basis: 30%;
    margin-bottom: 0;
  }
}

.footer__group--links .footer__links-group, .footer__logo {
  flex-basis: 100%;
}

@media only screen and (min-width: 1024px) {
  .footer__logo {
    flex-basis: 20%;
  }
}

.footer__logo img {
  min-width: 80px;
  max-width: 180px;
}

.footer__links {
  flex-basis: 100%;
}

@media only screen and (min-width: 1024px) {
  .footer__links {
    flex-basis: 40%;
  }
}

.footer__links-group {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.footer__links-group + .footer__links-group {
  padding-top: .5em;
}

@media only screen and (min-width: 1024px) {
  .footer__links-group + .footer__links-group {
    padding-top: 1.3em;
  }
}

.footer__links-group a {
  text-transform: uppercase;
  padding-right: 1em;
}

[data-glide-slideshow] .glide__track, [data-glide-slideshow] .glide__slides, [data-glide-slideshow] .glide__slide {
  width: 100%;
}

[data-glide-slideshow] .glide__slide {
  margin: 0;
}

[data-glide-slideshow] .glide__slide img {
  width: 100%;
}

.iti-slideshow, .iti-slideshow__slide {
  flex-direction: column;
  display: flex;
}

.iti-slideshow__slide--red .iti-slideshow__img {
  background-color: #ff4b64;
}

.iti-slideshow__slide--red a {
  -webkit-text-decoration-color: #ff4b64;
  text-decoration-color: #ff4b64;
}

.iti-slideshow__slide--blue .iti-slideshow__img {
  background-color: #237dd2;
}

.iti-slideshow__slide--blue a {
  -webkit-text-decoration-color: #237dd2;
  text-decoration-color: #237dd2;
}

.iti-slideshow__slide--pink .iti-slideshow__img {
  background-color: #e1b0bd;
}

.iti-slideshow__slide--pink a {
  -webkit-text-decoration-color: #e1b0bd;
  text-decoration-color: #e1b0bd;
}

.iti-slideshow__slide--yellow .iti-slideshow__img {
  background-color: #f0e678;
}

.iti-slideshow__slide--yellow a {
  -webkit-text-decoration-color: #f0e678;
  text-decoration-color: #f0e678;
}

.iti-slideshow__slide--orange .iti-slideshow__img {
  background-color: #e26956;
}

.iti-slideshow__slide--orange a {
  -webkit-text-decoration-color: #e26956;
  text-decoration-color: #e26956;
}

.iti-slideshow__slide--green .iti-slideshow__img {
  background-color: #54c8a0;
}

.iti-slideshow__slide--green a {
  -webkit-text-decoration-color: #54c8a0;
  text-decoration-color: #54c8a0;
}

.iti-slideshow__slide--grey .iti-slideshow__img {
  background-color: #c8c8c8;
}

.iti-slideshow__slide--grey a {
  -webkit-text-decoration-color: #c8c8c8;
  text-decoration-color: #c8c8c8;
}

.iti-slideshow__text {
  order: 2;
}

@media only screen and (min-width: 1024px) {
  .iti-slideshow__text {
    order: 0;
  }
}

.page-grid__block--slideshow .iti-slideshow__slide .iti-slideshow__text {
  margin-top: 30px;
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--slideshow .iti-slideshow__slide .iti-slideshow__text {
    margin-top: 0;
  }

  .page-grid__block--slideshow .iti-slideshow__slide .iti-slideshow__img {
    margin-top: 30px;
  }
}

.iti-slideshow__img img {
  mix-blend-mode: luminosity;
  object-fit: cover;
  object-position: center;
  height: calc(50.625vw - 42.1875px);
  filter: grayscale();
}

@media only screen and (min-width: 1440px) {
  .iti-slideshow__img img {
    height: calc(36vw - 35.4375px);
  }
}

@media only screen and (min-width: 1921px) {
  .iti-slideshow__img img {
    height: 655.763px;
  }
}

.iti-slideshow__nav {
  order: 2;
  justify-content: space-between;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .iti-slideshow__nav {
    order: 0;
  }
}

.iti-slideshow__nav-item {
  min-width: calc(25% - 11.25px);
  cursor: pointer;
  border: 0;
  outline: 0;
  flex-basis: calc(25% - 11.25px);
  padding-top: 15px;
}

@media only screen and (min-width: 1024px) {
  .iti-slideshow__nav-item {
    padding-top: 0;
    padding-bottom: 15px;
  }
}

.iti-slideshow__nav-item-content {
  width: 100%;
  height: 100%;
  height: 3px;
  background-color: #c8c8c8;
}

@media only screen and (min-width: 641px) {
  .iti-slideshow__nav-item-content {
    height: 5px;
  }
}

.iti-slideshow__nav-item--red .iti-slideshow__nav-item-content {
  background-color: #ff4b64;
}

.iti-slideshow__nav-item--blue .iti-slideshow__nav-item-content {
  background-color: #237dd2;
}

.iti-slideshow__nav-item--pink .iti-slideshow__nav-item-content {
  background-color: #e1b0bd;
}

.iti-slideshow__nav-item--yellow .iti-slideshow__nav-item-content {
  background-color: #f0e678;
}

.iti-slideshow__nav-item--orange .iti-slideshow__nav-item-content {
  background-color: #e26956;
}

.iti-slideshow__nav-item--green .iti-slideshow__nav-item-content {
  background-color: #54c8a0;
}

.iti-slideshow__nav-item--grey .iti-slideshow__nav-item-content {
  background-color: #c8c8c8;
}

.iti-slideshow__nav-item:hover .iti-slideshow__nav-item-content {
  background-color: #646464;
}

.page-grid {
  grid-auto-flow: dense;
  border-top: 3px solid #c8c8c8;
  grid-template-columns: repeat(12, 1fr);
  align-items: stretch;
  column-gap: 17px;
  display: grid;
  overflow: hidden;
}

@media only screen and (min-width: 641px) {
  .page-grid {
    border-top: 5px solid;
  }
}

[data-bg-color="red"] .page-grid {
  border-top-color: #ff4b64;
}

[data-bg-color="blue"] .page-grid {
  border-top-color: #237dd2;
}

[data-bg-color="yellow"] .page-grid {
  border-top-color: #f0e678;
}

[data-bg-color="orange"] .page-grid {
  border-top-color: #e26956;
}

[data-bg-color="pink"] .page-grid {
  border-top-color: #e1b0bd;
}

[data-bg-color="green"] .page-grid {
  border-top-color: #54c8a0;
}

[data-bg-color="grey"] .page-grid {
  border-top-color: #c8c8c8;
}

.page-grid.page-grid--no-border {
  border: 0;
}

@media only screen and (min-width: 1024px) {
  .page-grid:before {
    content: "";
    border-left: 5px solid #c8c8c8;
    position: absolute;
    top: 20px;
    bottom: 0;
    left: -2.5px;
  }
}

.page-grid p a, .page-grid h1 a, .page-grid h2 a, .page-grid h3 a, .page-grid h4 a, .page-grid h5 a, .page-grid h6 a, .page-grid ul a, .page-grid ol a, .page-grid li a {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.page-grid__block {
  grid-column: span 12;
  margin-top: -3px;
  padding: 15px 7.5px 30px;
  position: relative;
}

@media only screen and (min-width: 641px) {
  .page-grid__block {
    grid-column: span 6;
    margin-top: -5px;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block {
    grid-column: span 4;
  }
}

.page-grid__block:not(.page-grid__block--no-border) {
  border-top: 3px solid #c8c8c8;
}

@media only screen and (min-width: 641px) {
  .page-grid__block:not(.page-grid__block--no-border) {
    border-top: 5px solid #c8c8c8;
  }
}

.page-grid__block + .page-grid__block:before {
  content: "";
  border-left: 2px solid #646464;
  position: absolute;
  top: 14px;
  bottom: 14px;
  left: -9.5px;
}

@media only screen and (min-width: 641px) {
  .page-grid__block + .page-grid__block:before {
    top: 12px;
    bottom: 12px;
  }
}

.page-grid__block--col-1 {
  grid-column: span 6;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-1 {
    grid-column: span 6;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-1 {
    grid-column: span 1;
  }
}

.page-grid__block--col-md-1 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-1 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-1 {
    grid-column: span 1;
  }
}

.page-grid__block--col-2 {
  grid-column: span 6;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-2 {
    grid-column: span 6;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-2 {
    grid-column: span 2;
  }
}

.page-grid__block--col-md-2 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-2 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-2 {
    grid-column: span 2;
  }
}

.page-grid__block--col-3 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-3 {
    grid-column: span 6;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-3 {
    grid-column: span 3;
  }
}

.page-grid__block--col-md-3 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-3 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-3 {
    grid-column: span 3;
  }
}

.page-grid__block--col-4 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-4 {
    grid-column: span 6;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-4 {
    grid-column: span 4;
  }
}

.page-grid__block--col-md-4 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-4 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-4 {
    grid-column: span 4;
  }
}

.page-grid__block--col-5 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-5 {
    grid-column: span 6;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-5 {
    grid-column: span 5;
  }
}

.page-grid__block--col-md-5 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-5 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-5 {
    grid-column: span 5;
  }
}

.page-grid__block--col-6 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-6 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-6 {
    grid-column: span 6;
  }
}

.page-grid__block--col-md-6 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-6 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-6 {
    grid-column: span 6;
  }
}

.page-grid__block--col-7 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-7 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-7 {
    grid-column: span 7;
  }
}

.page-grid__block--col-md-7 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-7 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-7 {
    grid-column: span 7;
  }
}

.page-grid__block--col-8 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-8 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-8 {
    grid-column: span 8;
  }
}

.page-grid__block--col-md-8 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-8 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-8 {
    grid-column: span 8;
  }
}

.page-grid__block--col-9 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-9 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-9 {
    grid-column: span 9;
  }
}

.page-grid__block--col-md-9 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-9 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-9 {
    grid-column: span 9;
  }
}

.page-grid__block--col-10 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-10 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-10 {
    grid-column: span 10;
  }
}

.page-grid__block--col-md-10 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-10 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-10 {
    grid-column: span 10;
  }
}

.page-grid__block--col-11 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-11 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-11 {
    grid-column: span 11;
  }
}

.page-grid__block--col-md-11 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-11 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-11 {
    grid-column: span 11;
  }
}

.page-grid__block--col-12 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-12 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-12 {
    grid-column: span 12;
  }
}

.page-grid__block--col-md-12 {
  grid-column: span 12;
}

@media only screen and (min-width: 641px) {
  .page-grid__block--col-md-12 {
    grid-column: span 12;
  }
}

@media only screen and (min-width: 1024px) {
  .page-grid__block--col-md-12 {
    grid-column: span 12;
  }
}

.page-grid__block img {
  width: 100%;
}

.page-grid__block > * + *, .page-grid__block .two-columns__col > :not(p) + :not(p) {
  margin-top: 30px;
}

.page-grid__block--slideshow {
  padding: 0 0 30px;
}

.page-grid__block--slideshow .iti-slideshow__slide > * {
  margin: 0 7.5px;
}

.two-columns {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

@media only screen and (min-width: 641px) {
  .two-columns {
    flex-wrap: no-wrap;
  }
}

.two-columns__col {
  flex-basis: 100%;
}

@media only screen and (min-width: 641px) {
  .two-columns__col {
    flex-basis: calc(50% - 7.5px);
  }
}

.two-columns__col + .two-columns__col {
  margin-top: 3em;
}

@media only screen and (min-width: 641px) {
  .two-columns__col + .two-columns__col {
    margin-top: 0;
  }
}

.people {
  flex-wrap: wrap;
  display: flex;
}

@media only screen and (min-width: 641px) {
  .people {
    flex-wrap: no-wrap;
  }
}

.people__filters {
  flex-direction: column;
  flex-basis: 100%;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .people__filters {
    flex-basis: 25%;
    padding-right: 15px;
  }
}

.people__container {
  flex-basis: 100%;
  margin-top: 30px;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .people__container {
    flex-basis: 75%;
    margin-top: 0;
    padding-left: 15px;
  }
}

.people__content {
  width: calc(100% + 15px);
  grid-auto-flow: dense;
  grid-template-columns: repeat(12, 1fr);
  align-items: stretch;
  column-gap: 17px;
  margin-bottom: -2px;
  margin-left: -7.5px;
  margin-right: -7.5px;
  display: grid;
}

.people__member {
  text-align: center;
  border-bottom: 2px solid #c8c8c8;
  grid-column: span 12;
  padding: 15px 7.5px 30px;
  position: relative;
}

@media only screen and (min-width: 641px) {
  .people__member {
    text-align: left;
    grid-column: span 4;
  }
}

.people--small .people__member {
  grid-column: span 6;
}

@media only screen and (min-width: 641px) {
  .people--small .people__member {
    grid-column: span 4;
  }
}

@media only screen and (min-width: 1024px) {
  .people--small .people__member {
    grid-column: span 3;
  }
}

.people__member img {
  width: 50%;
  margin-bottom: 7.5px;
}

@media only screen and (min-width: 641px) {
  .people__member img {
    width: 70%;
  }
}

.people--small .people__member img {
  width: 70%;
}

[data-bg-color="red"] .people__member {
  border-bottom-color: #ff4b64;
}

[data-bg-color="blue"] .people__member {
  border-bottom-color: #237dd2;
}

[data-bg-color="yellow"] .people__member {
  border-bottom-color: #f0e678;
}

[data-bg-color="orange"] .people__member {
  border-bottom-color: #e26956;
}

[data-bg-color="pink"] .people__member {
  border-bottom-color: #e1b0bd;
}

[data-bg-color="green"] .people__member {
  border-bottom-color: #54c8a0;
}

[data-bg-color="grey"] .people__member {
  border-bottom-color: #c8c8c8;
}

.people__member + .people__member:before {
  content: "";
  border-left: 2px solid #646464;
  position: absolute;
  top: 14px;
  bottom: 14px;
  left: -9.5px;
}

@media only screen and (min-width: 641px) {
  .people__member + .people__member:before {
    top: 12px;
    bottom: 12px;
  }
}

[data-bg-color="red"] .people__member + .people__member:before {
  border-left-color: #ff4b64;
}

[data-bg-color="blue"] .people__member + .people__member:before {
  border-left-color: #237dd2;
}

[data-bg-color="yellow"] .people__member + .people__member:before {
  border-left-color: #f0e678;
}

[data-bg-color="orange"] .people__member + .people__member:before {
  border-left-color: #e26956;
}

[data-bg-color="pink"] .people__member + .people__member:before {
  border-left-color: #e1b0bd;
}

[data-bg-color="green"] .people__member + .people__member:before {
  border-left-color: #54c8a0;
}

[data-bg-color="grey"] .people__member + .people__member:before {
  border-left-color: #c8c8c8;
}

.numbers-grid {
  grid-auto-flow: dense;
  width: calc(100% + 15px);
  grid-template-columns: repeat(12, 1fr);
  align-items: stretch;
  column-gap: 17px;
  margin-left: -7.5px;
  margin-right: -7.5px;
  display: grid;
}

.numbers-grid__block {
  text-align: center;
  grid-column: span 6;
  padding: 15px 7.5px 30px;
  position: relative;
}

@media only screen and (min-width: 641px) {
  .numbers-grid__block {
    grid-column: span 4;
  }
}

@media only screen and (min-width: 1024px) {
  .numbers-grid__block {
    grid-column: span 3;
  }
}

.logos-grid {
  grid-auto-flow: dense;
  width: calc(100% + 15px);
  grid-template-columns: repeat(12, 1fr);
  align-items: stretch;
  column-gap: 17px;
  margin-left: -7.5px;
  margin-right: -7.5px;
  display: grid;
}

.logos-grid__block {
  grid-column: span 6;
  padding: 15px 7.5px 30px;
  position: relative;
}

@media only screen and (min-width: 641px) {
  .logos-grid__block {
    grid-column: span 4;
  }
}

@media only screen and (min-width: 1024px) {
  .logos-grid__block {
    grid-column: span 3;
  }
}

.logos-grid__block img + p {
  margin-top: 1em;
}

.sub-section {
  border-top: 2px solid #c8c8c8;
  padding-top: 7.5px;
  padding-bottom: 30px;
}

@media only screen and (min-width: 641px) {
  .sub-section {
    padding-left: 15px 7.5px;
    padding-right: 15px 7.5px;
  }
}

.sub-section + .sub-section {
  margin-top: 15px;
}

[data-bg-color="red"] .sub-section {
  border-top-color: #ff4b64;
}

[data-bg-color="blue"] .sub-section {
  border-top-color: #237dd2;
}

[data-bg-color="yellow"] .sub-section {
  border-top-color: #f0e678;
}

[data-bg-color="orange"] .sub-section {
  border-top-color: #e26956;
}

[data-bg-color="pink"] .sub-section {
  border-top-color: #e1b0bd;
}

[data-bg-color="green"] .sub-section {
  border-top-color: #54c8a0;
}

[data-bg-color="grey"] .sub-section {
  border-top-color: #c8c8c8;
}

.sub-section > * + * {
  margin-top: 15px;
}

.sub-section p + p {
  margin-top: 0;
}

.sub-section__title {
  margin-bottom: .5em;
}

.pub-list {
  flex-wrap: wrap;
  display: flex;
}

@media only screen and (min-width: 641px) {
  .pub-list {
    flex-wrap: no-wrap;
  }
}

.pub-list__filters {
  flex-direction: column;
  flex-basis: 100%;
  display: flex;
}

@media only screen and (min-width: 641px) {
  .pub-list__filters {
    flex-basis: 25%;
    padding-right: 15px;
  }
}

.pub-list__content {
  flex-basis: 100%;
  margin-top: 30px;
}

.pub-list__content > * + * {
  margin-top: 15px;
}

@media only screen and (min-width: 641px) {
  .pub-list__content {
    flex-basis: 75%;
    margin-top: 0;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 1024px) {
  .pub-list__content {
    flex-basis: 50%;
  }
}

.pub-list__pagination {
  text-align: center;
  border: 1px solid #0000;
  padding: .5em .2em;
  font-weight: 300;
}

@media only screen and (min-width: 641px) {
  .pub-list__pagination {
    width: 150px;
    text-align: left;
  }

  .pub-list .sub-section:first-child {
    border-top: 0;
    padding-top: 0;
  }
}

.pub-list__publication {
  margin-top: 30px;
}

@media only screen and (min-width: 641px) {
  .pub-list__publication {
    margin-top: 15px;
  }
}

.pub-list__publication:not(:first-child) {
  border-top: 1px solid #c8c8c8;
  padding-top: 15px;
}

@media only screen and (min-width: 641px) {
  .pub-list__publication:not(:first-child) {
    padding-top: 7.5px;
  }
}

[data-bg-color="red"] .pub-list__publication:not(:first-child) {
  border-color: #ff4b64;
}

[data-bg-color="blue"] .pub-list__publication:not(:first-child) {
  border-color: #237dd2;
}

[data-bg-color="yellow"] .pub-list__publication:not(:first-child) {
  border-color: #f0e678;
}

[data-bg-color="orange"] .pub-list__publication:not(:first-child) {
  border-color: #e26956;
}

[data-bg-color="pink"] .pub-list__publication:not(:first-child) {
  border-color: #e1b0bd;
}

[data-bg-color="green"] .pub-list__publication:not(:first-child) {
  border-color: #54c8a0;
}

[data-bg-color="grey"] .pub-list__publication:not(:first-child) {
  border-color: #c8c8c8;
}

/*# sourceMappingURL=article.b0b12b60.css.map */
