.page-grid {
  display: grid;
  grid-auto-flow: dense;
  align-items: stretch;
  column-gap: $block-gap + 2px;
  grid-template-columns: repeat(12, 1fr);
  overflow: hidden;
  border-top: $border-colored-width--mobile solid $color-grey;

  @media #{$breakpoint-sm-up} {
    border-top: $border-colored-width solid;
  }

  [data-bg-color="red"] & {
    border-top-color: $color-red;
  }

  [data-bg-color="blue"] & {
    border-top-color: $color-blue;
  }

  [data-bg-color="yellow"] & {
    border-top-color: $color-yellow;
  }

  [data-bg-color="orange"] & {
    border-top-color: $color-orange;
  }

  [data-bg-color="pink"] & {
    border-top-color: $color-pink;
  }

  [data-bg-color="green"] & {
    border-top-color: $color-green;
  }

  [data-bg-color="grey"] & {
    border-top-color: $color-grey;
  }

  &.page-grid--no-border {
    border: 0;
  }

  @media #{$breakpoint-md-up} {
    &:before {
      content: '';
      position: absolute;
      left: calc(#{$border-colored-width}/2 * -1);
      top: calc(#{$block-gap} + #{$border-colored-width});
      bottom: 0;
      border-left: $border-colored-width solid $color-grey;
    }
  }

  p, h1, h2, h3, h4, h5, h6, ul, ol, li {
    a {
      text-decoration-line: underline;
    }
  }

  &__block {
    position: relative;
    padding: $block-gap $block-gap/2;
    padding-bottom: calc(#{$block-gap}*2);
    grid-column: span 12;
    margin-top: -$border-colored-width--mobile;

    @media #{$breakpoint-sm-up} {
      grid-column: span 6;
      margin-top: -$border-colored-width;
    }

    @media #{$breakpoint-md-up} {
      grid-column: span 4;
    }

    &:not(&--no-border) {
      border-top: $border-colored-width--mobile solid $color-grey;

      @media #{$breakpoint-sm-up} {
        border-top: $border-colored-width solid $color-grey;
      }
    }

    & + .page-grid__block:before {
      content: '';
      position: absolute;
      left: calc((#{$block-gap}/2 + #{$border-vert-colored-width}) * -1);
      top: calc(#{$block-gap} - (#{$border-colored-width--mobile} - #{$border-vert-colored-width}));
      bottom: calc(#{$block-gap} - (#{$border-colored-width--mobile} - #{$border-vert-colored-width}));
      border-left: $border-vert-colored-width solid $color-grey-darker;

      @media #{$breakpoint-sm-up} {
        top: calc(#{$block-gap} - (#{$border-colored-width} - #{$border-vert-colored-width}));
        bottom: calc(#{$block-gap} - (#{$border-colored-width} - #{$border-vert-colored-width}));
      }
    }

    @for $i from 1 through 12 {
      &--col-#{$i} {
        @if $i >= 3 {
          grid-column: span 12;
        } @else {
          grid-column: span 6;
        }

        @media #{$breakpoint-sm-up} {
          @if $i >= 6 {
            grid-column: span 12;
          } @else {
            grid-column: span 6;
          }
        }

        @media #{$breakpoint-md-up} {
          grid-column: span $i;
        }
      }

      &--col-md-#{$i} {
        grid-column: span 12;

        @media #{$breakpoint-sm-up} {
          grid-column: span 12;
        }

        @media #{$breakpoint-md-up} {
          grid-column: span $i;
        }
      }
    }

    img {
      width: 100%;
    }

    > * + *,
    .two-columns__col > *:not(p) + *:not(p) {
      margin-top: $block-gap*2;
    }
  }

  &__block--slideshow {
    padding: 0px;
    padding-bottom: $block-gap*2;

    .iti-slideshow__slide > * {
      margin: 0 $block-gap/2;
    }
  }
}


.two-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media #{$breakpoint-sm-up} {
    flex-wrap: no-wrap;
  }


  &__col {
    flex-basis: 100%;

    @media #{$breakpoint-sm-up} {
      flex-basis: calc(50% - #{$block-gap}/2);
    }

    & + & {
      margin-top: 3em;
      @media #{$breakpoint-sm-up} {
        margin-top: 0;
      }
    }
  }

}
